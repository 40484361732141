<template>
  <div class="PostConnectCard">
    <div title dpadding>
      <div>{{ $locale["connect_with"] }}</div>
      <div strong v-size="`150%`">@{{ post.creator.user }}</div>
    </div>
    <div user dpadding border-top tcenter>
      <UserAvatar :user="post.creator" :size="`200px`" mauto />
    </div>
    <div padding-left padding-right padding-bottom>
      <span strong>{{ $locale["send_a_gift"] }}</span>
    </div>
    <div class="SendGiftPostCardProducts" dpadding border-top>
      <div class="Content">
        <a class="Product" v-for="(item, index) in products" :key="index" @click="sendProductView(item)">
          <div class="ProductContent">
            <div image><img :src="getProductImage(item)" /></div>
            <div name>
              <p text>{{ item.name }}</p>
            </div>
            <div price>{{ Go.number_format(item.price) }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post"],
  data: function() {
    return {
      loading: false,
      cache: Go.store("gifts_producs"),
      error: false,
      products: [],
    };
  },
  methods: {
    getProducts: async function() {
      this.loading = true;
      this.limit = this.$isMobile ? 9 : 8;

      try {
        this.type = encodeURI(JSON.stringify({ type: { $in: ["flowers", "gifts"] } }));
        this.sort = encodeURI(JSON.stringify({ type: -1, price: 1 }));
        this.req = await this.api.get(`products?limit=${this.limit}&sort=${this.sort}&type=${this.type}`);
        this.products = this.req.data.items;
        this.cache.set("products", this.req.data.items);
      } catch (error) {
        this.error = error;
      }

      this.loading = false;
    },
    getProductImage: function(item) {
      const media = item.media.find((item) => Go.includes(item.src, "."));
      return media ? media.src : "";
    },
    sendProductView: function(item) {
      if (!this.$user) {
        return this.requestUserLogin();
      }

      item.image = this.getProductImage(item);
      this.view({
        title: this.$locale["send_gift"],
        component: () => import("../incentives/SendGift.vue"),
        class: "menu bottom",
        closeOutside: true,
        animation: "bottomIn",
        product: item,
        creator: this.post.creator,
      });
    },
  },
  mounted: async function() {
    if (!this.cache.get("products")) {
      this.getProducts();
    } else {
      this.products = this.cache.get("products");
    }
  },
};
</script>

<style lang="scss">
.PostConnectCard {
  .UserAvatar .userOnlineStatus {
    width: $mpadding * 2;
    height: $mpadding * 2;
    border: solid 2px #fff;
    right: $mpadding * 0.6;
    top: $mpadding * 0.6;
  }
}
.SendGiftPostCardProducts {
  .Content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $mpadding/2;
  }

  .Product {
    text-align: center;
    cursor: pointer;
  }

  .Product:active .ProductContent {
    @include transform(scale(0.95));
    @include active_button();
  }

  .ProductContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: $mpadding/2 0 0 0;
    border-radius: $mpadding/2;
    @include transition(0.2s);
  }

  .ProductContent [image] {
    flex: 0.7;
    @include transition(0.2s);
  }

  .ProductContent [name] {
    margin: auto 0 0 0;
    padding: $mpadding $mpadding $mpadding/2 $mpadding;
  }

  .ProductContent [price] {
    padding: 0 0 $mpadding/2 0;
    font-family: $third_font_bold;
  }

  .ProductContent [name] [text] {
    @include ellipsisClamp(2);
  }

  .Product img {
    max-width: $mpadding * 4;
    width: 100%;
  }

  @include screen($tablet_width) {
    .Content {
      grid-template-columns: repeat(4, 1fr);
    }

    .Product img {
      max-width: $mpadding * 5;
    }

    .Product:hover .ProductContent {
      background-color: rgba($color: $alto, $alpha: 0.3);
    }

    .Product:hover .ProductContent [image] {
      @include transform(scale(1.08));
    }
  }
}
</style>
